import React, { useEffect, useState } from "react";
import { Constants, MeetingProvider } from "@videosdk.live/react-sdk";
import { LeaveScreen } from "./components/screens/LeaveScreen";
import { JoiningScreen } from "./components/screens/JoiningScreen";
import { ILSContainer } from "./interactive-live-streaming/ILSContainer";
import { MeetingAppProvider } from "./MeetingAppContextDef";
import { useParams } from "react-router-dom";

const VideoCall = () => {
  const [token, setToken] = useState("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJlOWE1ZTQ3YS02MDUyLTQ4MjUtYWE3ZC02NDlhNDFlYjM5Y2UiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTczNjMzMzk4NSwiZXhwIjoxODk0MTIxOTg1fQ.q8eBKD_rtNmANoO2Mdprykhu99rBDqWw4oKLmfn3yXU");
  const [meetingId, setMeetingId] = useState(null);
  const [participantName, setParticipantName] = useState("");
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);
  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  );
  const [meetingMode, setMeetingMode] = useState(Constants.modes.CONFERENCE);
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);

  let { id } = useParams();

  console.log(meetingId)

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);

  useEffect(()=>{
    setMeetingId(id)
  },[id])

  return (
    // <>
    //   {isMeetingStarted ? (
    //     <MeetingAppProvider
    //       selectedMic={selectedMic}
    //       selectedWebcam={selectedWebcam}
    //       initialMicOn={micOn}
    //       initialWebcamOn={webcamOn}
    //     >
    //       <MeetingProvider
    //         config={{
    //           meetingId,
    //           micEnabled: micOn,
    //           webcamEnabled: webcamOn,
    //           name: participantName ? participantName : "TestUser",
    //           mode: meetingMode,
    //           multiStream: false,
    //         }}
    //         token={token}
    //         reinitialiseMeetingOnConfigChange={true}
    //         joinWithoutUserInteraction={true}
    //       >
    //         <ILSContainer
    //           onMeetingLeave={() => {
    //             setToken("");
    //             setMeetingId("");
    //             setParticipantName("");
    //             setWebcamOn(false);
    //             setMicOn(false);
    //             setMeetingStarted(false);
    //           }}
    //           setIsMeetingLeft={setIsMeetingLeft}
    //           selectedMic={selectedMic}
    //           selectedWebcam={selectedWebcam}
    //           selectWebcamDeviceId={selectWebcamDeviceId}
    //           setSelectWebcamDeviceId={setSelectWebcamDeviceId}
    //           selectMicDeviceId={selectMicDeviceId}
    //           setSelectMicDeviceId={setSelectMicDeviceId}
    //           micEnabled={micOn}
    //           webcamEnabled={webcamOn}
    //           meetingMode={meetingMode}
    //           setMeetingMode={setMeetingMode}
    //         />
    //       </MeetingProvider>
    //     </MeetingAppProvider>
    //   ) : isMeetingLeft ? (
    //     <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
    //   ) : (
    //     <JoiningScreen
    //       participantName={participantName}
    //       setParticipantName={setParticipantName}
    //       setMeetingId={setMeetingId}
    //       setToken={setToken}
    //       setMicOn={setMicOn}
    //       micEnabled={micOn}
    //       webcamEnabled={webcamOn}
    //       setSelectedMic={setSelectedMic}
    //       setSelectedWebcam={setSelectedWebcam}
    //       setWebcamOn={setWebcamOn}
    //       onClickStartMeeting={() => {
    //         setMeetingStarted(true);
    //       }}
    //       startMeeting={isMeetingStarted}
    //       setIsMeetingLeft={setIsMeetingLeft}
    //       meetingMode={meetingMode}
    //       setMeetingMode={setMeetingMode}
    //     />
    //   )}
    // </>
    <>
    {meetingId &&  <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}
        >
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : "TestUser",
              mode: meetingMode,
              multiStream: false,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <ILSContainer
              onMeetingLeave={() => {
                setToken("");
                setMeetingId("");
                setParticipantName("");
                setWebcamOn(false);
                setMicOn(false);
                setMeetingStarted(false);
              }}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              selectWebcamDeviceId={selectWebcamDeviceId}
              setSelectWebcamDeviceId={setSelectWebcamDeviceId}
              selectMicDeviceId={selectMicDeviceId}
              setSelectMicDeviceId={setSelectMicDeviceId}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
              meetingMode={meetingMode}
              setMeetingMode={setMeetingMode}
            />
          </MeetingProvider>
        </MeetingAppProvider>}
    </>
  );
};

export default VideoCall;
